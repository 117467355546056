import React, { useState } from "react";
import { useParams } from "react-router";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import Spinner from "@/components/loading/spinner";
import { InvoiceNotFound } from "@/components/error/NotFound";
import InvoicePreview from "@/feature/invoices/read/InvoicePreview";
import { HorizontalFlex } from "@/components/layout/Flex";
import { EditInvoiceForm } from "@/feature/invoices/edit/form/EditInvoiceForm";
import { useFeatureFlag } from "@/util/useFeatureFlag";
import { FeatureFlags } from "@/models/user";
import WaitingForInvoices from "@/feature/invoices/WaitingForInvoices";
import GuardConditionCheck from "@/feature/invoices/Guard";

const EditInvoice = () => {
  const { id } = useParams();
  const [preview, setPreview] = useState<string>("/invoice/invoice.html");
  const hasInvoiceFeature = useFeatureFlag(FeatureFlags.Invoice);

  const { data, isLoading, isSuccess } = invoiceApi.useGetInvoiceQuery(
    id as string,
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!isSuccess) {
    return <InvoiceNotFound />;
  }

  if (hasInvoiceFeature) {
    return (
      <div className="relative grid gap-x-8 xl:grid-cols-[1fr_auto]">
        <HorizontalFlex className="relative w-full">
          <GuardConditionCheck>
            <EditInvoiceForm
              setPreview={setPreview}
              invoiceDraft={data}
              key={"company-form"}
            />
          </GuardConditionCheck>
        </HorizontalFlex>
        <InvoicePreview preview={preview} />
      </div>
    );
  } else {
    return <WaitingForInvoices />;
  }
};

export default EditInvoice;
