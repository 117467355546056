import { InvoiceDraft } from "@/models/invoice";
import { useConfirmModal } from "@/components/modal/useConfirmModal";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import React from "react";
import { t } from "i18next";
import { useToast } from "@/components/ui/use-toast";
import { Check, Copy, Eye, EyeOff, Pencil, Trash } from "lucide-react";
import { HorizontalFlex } from "@/components/layout/Flex";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import DownloadButton from "@/feature/documents/components/Card/CardHeader/DownloadButton";
import { useGetDocumentByIdQuery } from "@/api/endpoints/documentApi";

export const InvoiceActions = ({
  invoice,
  setSelectedInvoiceId,
  selectedInvoiceId,
}: {
  invoice: InvoiceDraft;
  setSelectedInvoiceId: (
    value:
      | ((prevState: string | undefined) => string | undefined)
      | string
      | undefined,
  ) => void;
  selectedInvoiceId: string | undefined;
}) => {
  const handlePreview = () => {
    if (selectedInvoiceId === invoice.id) {
      setSelectedInvoiceId(undefined);
      return;
    }
    setSelectedInvoiceId(invoice.id);
  };

  const navigation = useNavigate();

  function handleEdit() {
    navigation(`/inv/${invoice.id}`);
  }

  const { toast } = useToast();

  function handleDuplicate() {
    toast({
      title: "Die Funktion kommt bald...",
      icon: Copy,
    });
  }

  const { confirmDelete } = useConfirmModal();
  const [deleteInvoice] = invoiceApi.useDeleteMutation();
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    confirmDelete({
      text: t("component.invoices.deleteConfirm", {
        invoiceNumber: invoice.invoiceNumber,
      }),
      onAction: async () => {
        const res = await deleteInvoice(invoice.id);
        if ("data" in res) {
          toast({
            title: t("common.successDelete"),
            icon: Check,
          });
        }
      },
    });
  };

  const isEditable = invoice.status === "DRAFT";

  return (
    <HorizontalFlex
      className="transform opacity-50 transition-all duration-300 group-hover/edit:opacity-100"
      gap={0}
    >
      <ActionButton
        onClick={handlePreview}
        icon={
          selectedInvoiceId !== invoice.id ? (
            <Eye size={18} />
          ) : (
            <EyeOff size={18} />
          )
        }
        tooltip={t("component.invoices.table.watch")}
      />

      {isEditable ? (
        <ActionButton
          onClick={handleEdit}
          icon={<Pencil size={18} />}
          tooltip={t("component.invoices.table.edit")}
        />
      ) : (
        <DownloadDocumentInvoice invoice={invoice} />
      )}

      <ActionButton
        onClick={handleDuplicate}
        icon={<Copy size={18} />}
        tooltip={t("component.invoices.table.duplicate")}
      />
      {isEditable && (
        <ActionButton
          onClick={handleDelete}
          icon={<Trash size={18} />}
          tooltip={t("component.invoices.table.delete")}
          disabled={!isEditable}
        />
      )}
    </HorizontalFlex>
  );
};

const ActionButton = ({
  icon,
  onClick,
  tooltip,
  disabled,
}: {
  icon: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  tooltip: string;
}) => (
  <Button
    size="icon"
    variant="ghost"
    disabled={disabled}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick?.(e);
    }}
    title={tooltip}
    className={cn(
      "group text-muted-foreground",
      disabled && "cursor-not-allowed",
    )}
  >
    {icon}
  </Button>
);

function DownloadDocumentInvoice(props: { invoice: InvoiceDraft }) {
  const { data, isLoading } = useGetDocumentByIdQuery(props.invoice.documentId);
  if (isLoading || data === undefined) return null;
  return <DownloadButton biDocument={data} />;
}
