import React from "react";
import { BiDocument } from "@/models/document";
import { blobUrl } from "@/api/reduxApi";
import { VerticalFlex } from "@/components/layout/Flex";

const BigDocumentPreview = ({ document }: { document: BiDocument }) => {
  const url = document.uploadDocument?.blob
    ? blobUrl(document.uploadDocument?.blob)
    : "";

  return (
    <VerticalFlex
      className={"sticky pt-0"}
      style={{
        height: 0,
        top: "80px",
        overflow: "visible",
      }}
    >
      <div>
        {document.uploadDocument?.blob &&
          (document.uploadDocument.blob.mime === "application/pdf" ? (
            <iframe
              title={document.uploadDocumentId}
              className="w-full"
              style={{
                height: "calc(100vh - 96px)",
              }}
              src={url}
            />
          ) : (
            ["image/png", "image/jpeg"].includes(
              document.uploadDocument.blob.mime,
            ) && (
              <div
                className={"ml-auto flex overflow-y-auto"}
                style={{
                  height: "calc(100vh - 96px)",
                }}
              >
                <img
                  className="ml-auto flex h-full w-full object-contain"
                  src={url}
                  alt="Document preview"
                />
              </div>
            )
          ))}
      </div>
    </VerticalFlex>
  );
};

export default BigDocumentPreview;
