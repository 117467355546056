import { useTranslation } from "react-i18next";
import { Spinner } from "@react-pdf-viewer/core";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useGetAlLCategoriesQuery } from "@/api/endpoints/documentApi";
import { BiDocument, BiDocumentKind } from "@/models/document";
import React, { useEffect, useMemo } from "react";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";
import {
  GenericComboBox,
  GenericComboBoxOption,
} from "@/components/form/GenericComboBox";

export const EditCategorySelection = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  name,
  form,
  document,
  className,
}: {
  edit?: boolean;
  name: TName;
  form: UseFormReturn<T>;
  document: BiDocument;
  className?: string;
}) => {
  const { t } = useTranslation();

  const documentCategory = useWatch({ name: name });
  const documentKind = useWatch({ name: "kind" });

  const options = useBuildGroups(document.id, documentKind);
  let invalid = options && !options.find((d) => d.value === documentCategory);

  useEffect(() => {
    if (invalid) {
      form.setValue(name, "" as any);
    }
  }, [invalid]);

  if (!options) {
    return (
      <div className={className}>
        <Spinner />
      </div>
    );
  }

  return (
    <GenericComboBox
      className={className}
      form={form}
      placeholder={t("model.document.category_placeholder")}
      name={name}
      options={options}
    />
  );
};

function useBuildGroups(
  documentId: string,
  documentKind?: BiDocumentKind,
): GenericComboBoxOption[] | undefined {
  const { data, isLoading } = useGetAlLCategoriesQuery(documentId);

  const { t } = useTranslation();

  return useMemo(() => {
    if (!data) {
      return undefined;
    }

    let d = data.filter((d) => documentKind && d.kind.includes(documentKind));

    const withScore = d
      .filter((d) => d.score > 0)
      .map((v) => ({
        group: t("model.document.category_suggestion"),
        value: v.key,
        label: v.name,
        description: v.description,
      }));

    withScore.sort((a, b) => a.label.localeCompare(b.label));

    const withoutScore = d
      .filter((d) => d.score <= 0)
      .map((v) => ({
        value: v.key,
        label: v.name,
        group: v.category,
        description: v.description,
      }));

    withoutScore.sort((a, b) =>
      (a.group + ":" + a.label).localeCompare(b.group + ":" + b.label),
    );

    return withScore.concat(withoutScore);
  }, [data, documentKind]);
}
