import React, { FC, Fragment, useEffect, useState } from "react";
import { z } from "zod";
import {
  BiDocument,
  BiDocumentKind,
  DocumentPosition,
  SimpleDate,
} from "@/models/document";
import { useDocumentEditMutation } from "@/api/endpoints/documentApi";
import { toast } from "@/components/ui/use-toast";
import { Check, ChevronUp, MinusIcon, PlusIcon } from "lucide-react";
import { SaveBar } from "@/components/form/SaveBar";
import {
  useFieldArray,
  useForm,
  UseFormReturn,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FlexOne,
  HorizontalFlex,
  VerticalFlex,
} from "@/components/layout/Flex";
import { cn } from "@/lib/utils";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { FormSimpleText } from "@/components/form/FormSimpleText";
import { FormSimpleMoney } from "@/components/form/FormSimpleMoney";
import { FormSimpleSelect } from "@/components/form/FormSimpleSelect";
import { FormSimpleDate } from "@/components/form/FormSimpleDate";
import {
  InvoiceBadge,
  ReceiptBadge,
} from "@/feature/documents/components/Badge";
import { Form, FormLabel } from "@/components/ui/form";
import EditInvoicePerformancePeriod from "@/feature/documents/components/Card/CardBody/EditForm/EditInvoicePerformancePeriod";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { biDocumentSchema } from "@/models/EditDocumentModel";
import { formatMoney, formatSimpleDate } from "@/util/format";
import { EditCategorySelection } from "@/feature/documents/components/Card/CardBody/EditForm/EditCategorySelection";
import FormContactNameComboBox from "@/feature/documents/components/Card/CardBody/EditForm/FormContactNameComboBox";
import { useDocumentContext } from "@/feature/documents/DocumentContextProvider";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { FormSimpleNumber } from "@/components/form/FormSimpleNumber";

export type BiDocumentSchema = z.infer<typeof biDocumentSchema>;

interface EditDocumentCardBodyProps {
  onCancel: () => void;
  biDocument: BiDocument;
}

const EditDocumentCardBody: React.FC<EditDocumentCardBodyProps> = ({
  onCancel,
  biDocument,
}) => {
  const defaultValues = {
    kind: biDocument.kind || BiDocumentKind.Invoice,
    invoiceNumber: biDocument.invoiceNumber || "",
    invoiceDate: biDocument.invoiceDate,
    dueDate: biDocument.dueDate,
    performancePeriodFrom: biDocument.performancePeriodFrom,
    performancePeriodTo: biDocument.performancePeriodTo,
    issuer: {
      name: biDocument.issuer.name || "",
      address: biDocument.issuer.address || "",
      taxId: biDocument.issuer.taxId || "",
      iban: biDocument.issuer.iban || "",
      customerNumber: biDocument.issuer.customerNumber,
    },
    recipient: {
      name: biDocument.recipient.name || "",
      address: biDocument.recipient.address || "",
      taxId: biDocument.recipient.taxId || "",
      iban: biDocument.recipient.iban || "",
      customerNumber: biDocument.recipient.customerNumber,
    },
    invoicePositions:
      biDocument.invoicePositions && biDocument.invoicePositions.length
        ? biDocument.invoicePositions.map((p) => ({
            total: p.total,
            category: p.category || "",
            vatPercentage: parseFloat(p.vatPercentage),
          }))
        : [
            {
              total: 0,
              category: "",
              vatPercentage: 19,
            },
          ],
    deliveryDate: biDocument.deliveryDate,
    dirty: false,
  };
  const form = useForm<BiDocumentSchema>({
    resolver: zodResolver(biDocumentSchema),
    defaultValues,
  });

  const [documentEdit, documentEditResult] = useDocumentEditMutation();
  const { setMode } = useDocumentContext();

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={(e) => {
            form.handleSubmit(
              (v) => {
                documentEdit({
                  ...biDocument,
                  ...(v as any),
                }).then((result) => {
                  if ("data" in result) {
                    toast({
                      title: t("common.successSave"),
                      icon: Check,
                    });
                    setMode("edit-saved");
                  }
                });
              },
              (errors) => {
                console.error(errors);
              },
            )(e);
          }}
          className={cn("flex h-full flex-1 flex-col space-y-2")}
        >
          <HorizontalFlex align={"end"}>
            <FormSimpleSelect
              edit={true}
              form={form}
              label={t("model.document.kind.self")}
              name={"kind"}
              options={[
                {
                  label: t("model.document.kind.self"),
                  options: [
                    {
                      value: BiDocumentKind.Invoice,
                      label: <InvoiceBadge />,
                    },
                    {
                      value: BiDocumentKind.Receipt,
                      label: <ReceiptBadge />,
                    },
                  ],
                },
              ]}
            />
            <FormSimpleText
              label={t("model.document.invoiceNumber")}
              edit={true}
              form={form}
              className={"flex-1"}
              name={"invoiceNumber"}
              placeholder={t("model.document.invoiceNumber")}
            />
            <FormSimpleDate
              label={t("model.document.invoiceDate")}
              className={"w-[175px] flex-shrink-0"}
              form={form}
              name={"invoiceDate"}
              edit={true}
            />
          </HorizontalFlex>

          <InvoiceBody edit={true} form={form} document={biDocument} />

          <FlexOne />
          <HorizontalGradientLine />
          <SaveBar
            result={documentEditResult}
            className={"mx-auto pt-4"}
            onAbort={() => {
              onCancel();
              form.reset();
            }}
          />
        </form>
      </Form>
    </>
  );
};

export default EditDocumentCardBody;

const InvoiceBody: FC<{
  document: BiDocument;
  edit: boolean;
  form: UseFormReturn<z.infer<typeof biDocumentSchema>>;
}> = ({ form, document, edit }) => {
  const { t } = useTranslation();
  const [showTax, setShowTax] = useState(false);

  let [formInvoiceDate, documentKind, invoicePositions]: [
    SimpleDate,
    BiDocumentKind,
    DocumentPosition[] | null,
  ] = useWatch({
    name: ["invoiceDate", "kind", "invoicePositions"],
  });
  let invoiceDate = formInvoiceDate
    ? {
        year: formInvoiceDate.year ?? 0,
        month: formInvoiceDate.month ?? 0,
        day: formInvoiceDate.day ?? 0,
      }
    : undefined;

  let taxes = (invoicePositions || []).map((pos) => {
    let tax = parseFloat(pos.vatPercentage) / 100;
    if (tax < 0) return { taxRate: "0", taxAmount: 0 };
    let net = Math.round(pos.total / (1 + tax));

    return {
      taxRate: pos.vatPercentage,
      taxAmount: pos.total - net,
    };
  });

  let totalGrossAmount = (invoicePositions || [])
    .map((pos) => {
      return pos.total;
    })
    .reduce((a, b) => a + b);

  let totalNetAmount =
    totalGrossAmount - taxes.map((v) => v.taxAmount).reduce((a, b) => a + b);

  const issuer = documentKind !== BiDocumentKind.Invoice;
  let issuerCustomerNumber = useWatch({ name: "issuer.customerNumber" });
  let recipientCustomerNumber = useWatch({ name: "recipient.customerNumber" });
  let customerNumber =
    documentKind == BiDocumentKind.Invoice
      ? recipientCustomerNumber
      : issuerCustomerNumber;

  useEffect(() => {
    setShowTax(!customerNumber);
  }, [customerNumber]);

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "invoicePositions",
  });

  return (
    <VerticalFlex gap={4}>
      <HorizontalFlex align={"stretch"}>
        <EditInvoicePerformancePeriod
          className={"flex-shrink flex-grow"}
          document={document}
          form={form}
          placeholderSingle={
            invoiceDate ? formatSimpleDate(invoiceDate) : undefined
          }
        />
        <FormSimpleDate
          label={t("model.document.dueDate")}
          placeholder={invoiceDate ? formatSimpleDate(invoiceDate) : undefined}
          optional={true}
          className={"w-[175px]"}
          form={form}
          name={"dueDate"}
          edit={edit}
        />
      </HorizontalFlex>

      <Separator orientation={"horizontal"} className={"bg-muted"} />

      {issuer && (
        <FormLabel className={"pt-4"}>
          {t("model.document.issuer.self")}
        </FormLabel>
      )}
      {!issuer && (
        <FormLabel className={"pt-4"}>
          {t("model.document.recipient.self")}
        </FormLabel>
      )}

      <VerticalFlex gap={0}>
        <HorizontalFlex>
          <FormContactNameComboBox form={form} className={"flex-1"} />
          <Button
            type={"button"}
            variant={"ghost"}
            onClick={() => setShowTax((v) => !v)}
          >
            <ChevronUp
              size={16}
              className={cn("transition-all", showTax && "rotate-180")}
            />
          </Button>
        </HorizontalFlex>
        <div
          className={cn(
            "overflow-hidden transition-all",
            showTax ? "max-h-40" : "max-h-0",
          )}
        >
          <FormSimpleText
            edit={edit}
            itemClassName={"flex-1 py-2"}
            inputClassName={"min-w-32"}
            form={form}
            name={issuer ? "issuer.taxId" : "recipient.taxId"}
            placeholder={t("model.document.issuer.taxId")}
            label={t("model.document.issuer.taxId")}
          />
          <FormSimpleText
            edit={edit}
            itemClassName={"flex-1 py-2"}
            inputClassName={"min-w-32"}
            form={form}
            name={issuer ? "issuer.iban" : "recipient.iban"}
            placeholder={t("model.document.issuer.iban")}
            label={t("model.document.issuer.iban")}
          />
        </div>
        <FormSimpleText
          edit={edit}
          form={form}
          itemClassName={"py-2"}
          displayClassName={"text-sm font-medium text-muted-foreground"}
          className={"bg-primary/10 text-sm font-medium text-muted-foreground"}
          name={issuer ? "issuer.address" : "recipient.address"}
          placeholder={t("model.document.issuer.address")}
          textarea
        />
      </VerticalFlex>

      <Separator orientation={"horizontal"} className={"bg-muted"} />

      {fields.map((field, index) => (
        <VerticalFlex
          className={"rounded bg-muted/25 p-4 shadow"}
          key={"invoicePos:" + index}
        >
          <FormLabel>
            <HorizontalFlex gap={8} align={"center"} justify={"between"}>
              <span>
                {t("model.document.invoicePositions.self_one")} #{index + 1}
              </span>
            </HorizontalFlex>
          </FormLabel>
          <HorizontalFlex gap={8}>
            <FormSimpleNumber
              itemClassName={"flex-1"}
              form={form}
              name={`invoicePositions.${index}.vatPercentage`}
              prefix={() => (
                <span className={"pt-0.5 text-muted-foreground"}>
                  {t("common.taxAmount")}
                </span>
              )}
              postfix={() => <span className={"ml-3 pt-0.5"}>{"%"}</span>}
            />
            <FormSimpleMoney
              prefix={() => (
                <span className={"pt-0.5 text-muted-foreground"}>
                  {t("common.grossAmount")}
                </span>
              )}
              itemClassName={"flex-1"}
              form={form}
              name={`invoicePositions.${index}.total`}
              className={"font-semibold"}
            />
          </HorizontalFlex>
          <HorizontalFlex>
            <EditCategorySelection
              className={"flex-1"}
              edit={edit}
              form={form}
              document={document}
              name={`invoicePositions.${index}.category`}
            />
            {index > 0 && (
              <Button
                variant={"ghost"}
                onClick={() => remove(index)}
                type={"button"}
              >
                <MinusIcon size={16} />
              </Button>
            )}
            {index == 0 && (
              <Button
                type={"button"}
                variant={"ghost"}
                onClick={() =>
                  append({
                    total: 0.0,
                    vatPercentage: 19,
                    category: invoicePositions?.[0]?.category || "",
                  })
                }
              >
                <PlusIcon size={16} />
              </Button>
            )}
          </HorizontalFlex>
        </VerticalFlex>
      ))}

      <VerticalFlex className={"text-sm"}>
        <HorizontalFlex>
          <span>{t("common.netAmount")}</span>
          <FlexOne />
          {formatMoney(totalNetAmount)}
        </HorizontalFlex>
        {taxes
          .filter((t) => t.taxRate !== "0")
          .map((tax) => (
            <HorizontalFlex>
              <span>
                {t("common.taxAmount")} ({tax.taxRate}%)
              </span>
              <FlexOne />
              {formatMoney(tax.taxAmount)}
            </HorizontalFlex>
          ))}
        <Separator orientation={"horizontal"} />
        <HorizontalFlex>
          <span>{t("common.total")}</span>
          <FlexOne />
          {formatMoney(totalGrossAmount)}
        </HorizontalFlex>
      </VerticalFlex>
    </VerticalFlex>
  );
};
