import React, { useRef, useState } from "react";
import { HorizontalFlex, VerticalFlex } from "@/components/layout/Flex";
import { DataTableMultiselect } from "@/components/display/DataTableMultiselect";
import { t } from "i18next";
import { InvoiceDraft, InvoiceRequest } from "@/models/invoice";
import { DataTableSortSelector } from "@/components/display/DataTableSortSelector";
import { DataTableNoEntriesHandler } from "@/components/display/DataTableNoEntries";
import Spinner from "@/components/loading/spinner";
import { invoiceApi } from "@/api/endpoints/invoiceApi";
import { FilterInner, FilterParameters, FilterResult } from "@/api/types";
import {
  DataTablePagination,
  DataTablePaginationElement,
} from "@/components/display/DataTablePagination";
import {
  DataTable,
  DataTableColumn,
  Sort,
} from "@/components/display/DataTable";
import { Path } from "@/util/DeepProps";
import { formatDateTime } from "@/util/format";
import { cn } from "@/lib/utils";
import InvoicePreview from "@/feature/invoices/read/InvoicePreview";
import { InvoiceActions } from "@/feature/invoices/read/_table/InvoiceActions";
import { DeepPartial } from "react-hook-form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Link } from "react-router-dom";

type SetSortType = (
  value:
    | ((
        prevState: [keyof InvoiceDraft, "asc" | "desc"] | null,
      ) => [keyof InvoiceDraft, "asc" | "desc"] | null)
    | [keyof InvoiceDraft, "asc" | "desc"]
    | null,
) => void;

type SetFilterType = (
  value:
    | ((prevState: FilterInner<InvoiceDraft>) => FilterInner<InvoiceDraft>)
    | FilterInner<InvoiceDraft>,
) => void;

interface FiltersSectionProps {
  setFilter: SetFilterType;
  setSort: SetSortType;
}

interface ContentSectionProps {
  data: FilterResult<InvoiceDraft> | undefined;
  isLoading: boolean;
  onSortChange: SetSortType;
  columns: DataTableColumn<InvoiceDraft>[];
  onItemClick: (item: InvoiceDraft) => void;
  paginationRef: React.RefObject<DataTablePaginationElement<InvoiceDraft>>;
  setFilterParams: (params: FilterParameters<InvoiceDraft>) => void;
  selectedInvoiceId?: string | undefined;
}

const InvoiceTable = () => {
  const [sort, setSort] = useState<Sort<Path<InvoiceDraft>>>(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<
    string | undefined
  >();
  const [filter, setFilter] = useState<FilterInner<InvoiceDraft>>({});
  const [filterParams, setFilterParams] =
    useState<FilterParameters<InvoiceDraft>>();
  const paginationRef = useRef<DataTablePaginationElement<InvoiceDraft>>(null);
  const [preview, setPreview] = useState<string>("");

  const [triggerPreview, { data: previewData, isLoading: previewIsLoading }] =
    invoiceApi.useLazyPreviewQuery();

  const { data, isLoading } = invoiceApi.useFilterQuery(
    {
      sort: sort ? sort[0] : "updatedAt",
      direction: sort ? sort[1] : "desc",
      ...filterParams,
      ...filter,
    },
    { skip: !filter || !filterParams },
  );

  const handlePreview = (invoice: InvoiceDraft) => {
    setSelectedInvoiceId(invoice.id); // Setzt die aktuelle Vorschau-Rechnung
    triggerPreview({
      id: invoice.id,
      body: invoice.draftRequest as DeepPartial<InvoiceRequest>,
    })
      .then((response) => {
        if (response.data) {
          setPreview(response.data);
        }
      })
      .catch((err) => {
        console.error("Error fetching preview:", err);
      });
  };

  const columns: DataTableColumn<InvoiceDraft>[] = [
    {
      header: t("component.invoices.table.invoiceNumber"),
      cell: (item) => <InvoiceNumberCell item={item} />,
    },
    {
      header: t("component.invoices.table.status.label"),
      cell: (item) => t(`component.invoices.table.status.${item.status}`),
    },
    {
      header: t("component.invoices.table.type"),
      cell: (item) => item.typeCode,
    },
    {
      header: t("component.invoices.table.lastEdited"),
      cell: (item) => formatDateTime(new Date(item.updatedAt)),
    },
    {
      cell: (item) => (
        <InvoiceActions
          invoice={item}
          setSelectedInvoiceId={setSelectedInvoiceId}
          selectedInvoiceId={selectedInvoiceId}
        />
      ),
    },
  ];

  return (
    <VerticalFlex>
      <FiltersSection setFilter={setFilter} setSort={setSort} />
      <HorizontalFlex>
        <ContentSection
          data={data}
          isLoading={isLoading}
          onSortChange={setSort}
          columns={columns}
          onItemClick={handlePreview}
          paginationRef={paginationRef}
          setFilterParams={setFilterParams}
          selectedInvoiceId={selectedInvoiceId}
        />
        {selectedInvoiceId && (
          <PreviewSection
            selectedInvoiceId={selectedInvoiceId}
            preview={preview}
            previewIsLoading={previewIsLoading}
          />
        )}
      </HorizontalFlex>
    </VerticalFlex>
  );
};

const FiltersSection = ({ setFilter, setSort }: FiltersSectionProps) => (
  <HorizontalFlex>
    <DataTableMultiselect<InvoiceDraft>
      selectGroups={[]}
      placeholder={t("component.invoices.table.search")}
      textSearchKeys={["invoiceNumber", "draftRequest", "typeCode"]}
      onFilterChange={setFilter}
    />
    <div className="flex flex-1">
      <DataTableSortSelector<InvoiceDraft>
        options={[
          {
            label: t("component.invoices.table.lastEdited"),
            value: "updatedAt",
          },
          { label: t("model.document.invoiceNumber"), value: "invoiceNumber" },
        ]}
        onChange={setSort}
        default={["updatedAt", "desc"]}
      />
    </div>
  </HorizontalFlex>
);

const ContentSection = ({
  data,
  isLoading,
  onSortChange,
  columns,
  onItemClick,
  paginationRef,
  setFilterParams,
  selectedInvoiceId,
}: ContentSectionProps) => (
  <VerticalFlex gap={8} className="flex-auto transition-all duration-300">
    <DataTableNoEntriesHandler result={data} />
    {isLoading && <Spinner />}
    {!isLoading && data && data.items.length !== 0 && (
      <DataTable<InvoiceDraft>
        data={data.items}
        onSortChange={onSortChange}
        columns={columns}
        selected={[selectedInvoiceId as string]}
        onClick={(item) => onItemClick(item)}
      />
    )}
    <DataTablePagination
      ref={paginationRef}
      defaultPageSize={25}
      result={data}
      onChange={setFilterParams}
    />
  </VerticalFlex>
);

const PreviewSection = ({
  selectedInvoiceId,
  preview,
  previewIsLoading,
}: {
  selectedInvoiceId: string | undefined;
  preview: string;
  previewIsLoading: boolean;
}) => (
  <VerticalFlex
    className={cn(
      "transition-shadow duration-300 hover:shadow-lg",
      selectedInvoiceId ? "flex" : "hidden",
    )}
    gap={8}
    tabIndex={0}
  >
    {previewIsLoading ? <Spinner /> : <InvoicePreview preview={preview} />}
  </VerticalFlex>
);

const InvoiceNumberCell = ({ item }: { item: InvoiceDraft }) => {
  // TODO i18n &  Verlinkung auf das Dokument, sobald möglich
  if (item.status === "DRAFT") {
    return <span>{item.invoiceNumber}</span>;
  } else {
    return (
      <HoverCard>
        <HoverCardTrigger>
          <HorizontalFlex className="transition-all">
            <span>{item.invoiceNumber}</span>
          </HorizontalFlex>
        </HoverCardTrigger>
        <HoverCardContent>
          <Link to="/documents" className="underline">
            Mehr Infos zu {item.invoiceNumber}
          </Link>
        </HoverCardContent>
      </HoverCard>
    );
  }
};
export default InvoiceTable;
