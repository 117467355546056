import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { FormField } from "../FormField";
import { FlexOne, HorizontalFlex } from "@/components/layout/Flex";
import CountrySelect from "@/components/form/CountrySelect";
import { ListPlus, MapPin } from "lucide-react";
import { Button } from "@/components/ui/button";
import { t } from "i18next";
import { FormSimpleText } from "@/components/form/FormSimpleText";

interface AddressFormFieldsProps {
  form: UseFormReturn<any>;
  prefix: string;
}

export const AddressFormFields = ({ form, prefix }: AddressFormFieldsProps) => {
  const [showLineTwo, setShowLineTwo] = useState(false);
  const [showLineThree, setShowLineThree] = useState(false);
  return (
    <div className="grid grid-cols-1 gap-3">
      <MapPin className={"ml-auto h-6 text-muted-foreground/10"} />
      <FlexOne></FlexOne>

      <HorizontalFlex>
        <FormSimpleText
          form={form}
          name={`${prefix}address.postcode`}
          label="Postleitzahl"
        />
        <FormSimpleText
          form={form}
          name={`${prefix}address.city`}
          label="Ort"
        />
        <CountrySelect form={form} name={`${prefix}address.countryId`} />
      </HorizontalFlex>
      <FormSimpleText
        form={form}
        name={`${prefix}address.line1`}
        label={t("component.companyForm.address.lineOne")}
      />
      {!showLineTwo && (
        <Button
          size={"icon"}
          variant={"ghost"}
          type={"button"}
          className={"text-muted-foreground"}
          onClick={() => setShowLineTwo(true)}
        >
          <ListPlus />
        </Button>
      )}
      {showLineTwo && (
        <>
          <FormSimpleText
            form={form}
            name={`${prefix}address.line2`}
            placeholder={t("component.companyForm.address.lineTwo")}
          />
          {!showLineThree && (
            <Button
              size={"icon"}
              variant={"ghost"}
              type={"button"}
              className={"text-muted-foreground"}
              onClick={() => setShowLineThree(true)}
            >
              <ListPlus />
            </Button>
          )}
        </>
      )}
      {showLineThree && (
        <FormSimpleText
          form={form}
          name={`${prefix}address.line3`}
          placeholder={t("component.companyForm.address.lineTwoPlaceholder")}
        />
      )}
    </div>
  );
};
