import { CaretDownIcon, CheckIcon } from "@radix-ui/react-icons";
import { UseFormReturn } from "react-hook-form";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { t } from "i18next";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";
import { removeDuplicatesByKey } from "@/util/unique";
import { useMemo, useState } from "react";

export interface GenericComboBoxOption {
  group?: string;
  label: string;
  description?: string;
  shortcut?: string;
  disabled?: boolean;
  value: string;
}

export const GenericComboBox = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  form,
  name,
  options,
  placeholder,
  className,
  onSelect,
  width = "full",
  size = "md",
}: {
  form: UseFormReturn<T>;
  name: TName;
  options: GenericComboBoxOption[];
  placeholder?: string;
  className?: string;
  onSelect?: (selectedValue: string) => void;
  size?: "sm" | "md" | "lg";
  width?: "full" | "auto";
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const groups = useMemo(() => {
    return removeDuplicatesByKey(
      options.map((o) => o.group || ""),
      (s) => s,
    );
  }, [options]);

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    `w-${width} justify-between bg-transparent`,
                    !field.value && "text-muted-foreground",
                  )}
                >
                  <span className={`w-18 truncate text-left`}>
                    {field.value
                      ? options.find((option) => option.value === field.value)
                          ?.label
                      : placeholder}
                  </span>
                  {size === "md" && (
                    <CaretDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  )}
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className={cn(
                "p-0",
                size === "md" && "w-64",
                size === "lg" && "w-[32rem]",
              )}
            >
              <Command>
                <CommandInput
                  placeholder={placeholder || t("common.search")}
                  className="h-9"
                />
                <CommandList>
                  <CommandEmpty>{t("common.noDataResults")}</CommandEmpty>
                  {groups.flatMap((g) => {
                    const groupOptions = options.filter(
                      (o) => (o.group || "") === g,
                    );

                    return (
                      <CommandGroup
                        key={g}
                        heading={
                          <span className={"text-lg text-foreground"}>{g}</span>
                        }
                      >
                        {groupOptions.map((option, index) => (
                          <GenericComboboxItem
                            key={option.value}
                            index={index}
                            selected={field.value}
                            option={option}
                            onSelect={(value) => {
                              form.setValue(name, value as any);
                              setOpen(false);
                            }}
                          />
                        ))}
                      </CommandGroup>
                    );
                  })}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const GenericComboboxItem = ({
  option,
  index,
  selected,
  onSelect,
}: {
  option: GenericComboBoxOption;
  index?: number;
  selected: string;
  onSelect: (value: string) => void;
}) => {
  return (
    <CommandItem
      value={option.label}
      key={option.value}
      onSelect={() => {
        if (!option.disabled) onSelect(option.value);
      }}
      className={cn(
        (index || 0) % 2 === 0 && "bg-muted-foreground/5",
        "py-4",
        option.disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer",
      )}
    >
      <HorizontalGradientLine />

      <span className={"ml-4 flex w-full flex-col text-left"}>
        {option.shortcut && (
          <span className={"ml-auto text-xs text-muted-foreground"}>
            {option.shortcut}
          </span>
        )}
        {option.label}
        {option.description && (
          <span className={"text-xs text-muted-foreground"}>
            {option.description}
          </span>
        )}
      </span>

      {!option.disabled && (
        <CheckIcon
          className={cn(
            "ml-auto h-4 w-4",
            selected === option.value ? "opacity-100" : "opacity-0",
          )}
        />
      )}
    </CommandItem>
  );
};
